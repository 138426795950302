@import url('//fonts.googleapis.com/css?family=Poppins&display=swap');
@import url('//fonts.cdnfonts.com/css/sf-pro-display');

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.whiteIcon {
  filter: invert(100%) sepia(2%) saturate(2570%) hue-rotate(180deg) brightness(94%) contrast(93%)
}

h1.bold,
h2.bold,
h3.bold,
h4.bold,
h5.bold,
h6.bold {
  font-weight: 500;
}

.legendTitle {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */

  letter-spacing: 0.5px;
  text-transform: uppercase;

  /* On Surface/Light/Low-emphasis */

  color: rgba(255, 255, 255, 0.42);

  margin-bottom: 10px;
}

.legendText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  /* On Surface/Light/High-emphasis */

  color: rgba(255, 255, 255, 0.9);
}

.subtitle {
  /* Body / Regular / Medium */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* On Surface/Light/Low-emphasis */
  color: rgba(255, 255, 255, 0.42) !important;
}

.reportSection {
  background-color: #232429;
  border-color: #99FFFF;
  border-radius: 8px;
  border-width: 2px;
  margin-top: 40px;
  padding: 40px;
}


.onlyPrint {
  display: none;
}

@media print {

  .noPrint {
    display: none !important;
  }

  .onlyPrint {
    display: block !important;
  }

  .printPageBreak {
    page-break-before: always !important;
  }

  button {
    display: none !important;
  }

  /* Convert background to white and text to black */
  .body {
    background-color: #fff !important;
    color: #000 !important;
  }

  /* Hide navigation Bar */
  .sideNav {
    display: none !important;
    width: 0 !important;
  }

  /* Hide header */
  .reportHeader {
    display: none !important;
  }

  /* Expand container */
  .css-ilte95 {
    background-color: #fff !important;
    color: #000 !important;
    margin-left: 0px !important;
    padding: 0px !important;;
  }

  /* Remove summary boxes on reporting page */
  .css-flw4nu {
    display: none !important;
  }

  .reportSection {
    background-color: #fff !important;
    border-color: #fff !important;
    color: #000 !important;
  }

  .chakra-text {
    color: #000 !important;
  }

}

.backfaceInvisible {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari */
}